import React, { useState, useEffect, createContext } from "react";
import { supabase } from "../supabaseClient";

const SupabaseContext = createContext();

const SupabaseProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const fetchSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error("Error fetching session:", error);
      } else {
        setSession(data);
        setUser(data?.user ?? null);
      }
    };

    fetchSession();

    const unsubscribe = supabase.auth.onAuthStateChange(
      async (event, session) => {
        setSession(session);
        setUser(session?.user ?? null);
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  const value = {
    session,
    user,
  };

  return (
    <SupabaseContext.Provider value={value}>{children}</SupabaseContext.Provider>
  );
};

export { SupabaseContext, SupabaseProvider };